import { html } from 'lit-html';
import { store } from '../../state/store';
import { map } from 'lit-html/directives/map.js';
import { when } from 'lit-html/directives/when.js';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { sectionProducts, sectionSearchSuggestions } from '../../constructor/client';
import { resultTemplate } from '../plp/result';
import { getLocale } from '../../util/locale';
import { getCurrency } from '../../util/currency';
import { getEventId } from '../../util/offers';
import { selectVariation } from './state';
import '../../components/Carousel';
import '../../components/CarouselButton';
import '../../components/Spinner';
import { fixSiteURL } from '../../util/url';
import { trackAutosuggestPopularClick, trackAutosuggestResultClick } from './tracking';

export const isConstructorAutoSuggestEnabled = () => !!window?.mv?.constructor?.autosuggest?.enabled;

// NB: this isn't a real section in Constructor, it's just used for tracking
// selection of popular search terms retrieved from the MV database.
const sectionPopularSearchSuggestions = 'Popular Search Suggestions';

export const simpleTemplate = (state) => {
  const {
    loadingResults,
    query,
    results,
    popularSearches,
  } = state.autosuggest;

  const searchSuggestions = results?.sections?.[sectionSearchSuggestions];

  return html`
    ${when(
      loadingResults,
      () => html`
        <div class="search-results__loading"></div>
      `,
      () => html`
        <ul class="search-results__list">
          ${when(
            searchSuggestions?.length,
            () => html`
              ${map(searchSuggestions, (result) => html`
                <li
                  class="search-result__item"
                  data-cnstrc-item-section="${ sectionSearchSuggestions }"
                  data-cnstrc-item-name="${ result.value }"
                >
                  <a href="${fixSiteURL(`/search?q=${encodeURIComponent(result.value)}`)}" @click=${trackAutosuggestResultClick}>${result.value}</a>
                </li>
              `)}
            `,
            () => html`
              ${when(
                query,
                () => html`
                `,
                () => html`
                  ${when(popularSearches?.length, () => html`
                    <li class="search-results__title">Popular Searches</li>
                    ${map(popularSearches, (result) => html`
                      <li
                        class="search-results__item"
                        data-cnstrc-item-section="${ sectionPopularSearchSuggestions }"
                        data-cnstrc-item-name="${ result.name }"
                      >
                        <a href="${fixSiteURL(result.url)}" @click=${trackAutosuggestPopularClick}>${result.name}</a>
                      </li>
                    `)}
                  `)}
                `,
              )}
            `,
          )}
        </ul>
      `,
    )}
  `;
};

export const searchandiseTemplate = (state) => {
  const {
    query,
    results,
    popularSearches,
    exploreContent,
    loadingResults,
    selectedVariations,
  } = state.autosuggest;

  const searchFailed = query && !loadingResults && !results;

  let searchSuggestions = [];
  let products = [];
  let totalResults = 0;
  if (results) {
    searchSuggestions = results.sections?.[sectionSearchSuggestions];
    products = results.sections?.[sectionProducts];
    totalResults = results.total_num_results_per_section[sectionProducts] ?? 0;
  }

  const locale = getLocale();
  const currency = getCurrency();
  const eventId = getEventId();

  return html`
    <div class="searchandise-content-wrap l-full m-full">
      ${when(
        loadingResults,
        () => html`
          <mv-spinner class="searchandise-content__loading searchandise-content__loading--active"></mv-spinner>
        `,
        () => html`
          ${when(
            query && !products.length && !searchFailed,
            () => html`
              <div class="searchandise-content__no-results searchandise-content__no-results--show m-full">
                No search results for&nbsp;<span>${query}</span>
              </div>
            `,
          )}

          <div class="grid--flex searchandise-content__with-results">
            <div class="l-one-quarter m-full searchandise-content__left">
              <div class="searchandise-content__related searchandise-content--panel--active">
                <h3 class="searchandise-content__title">
                  ${searchSuggestions.length || !popularSearches?.length ? 'Related searches' : 'Popular searches'}
                </h3>
                <ul class="searchandise-content__list">
                  ${when(
                    searchSuggestions.length,
                    () => html`
                      ${map(searchSuggestions, (result) => html`
                        <li
                          class="searchandise-content__list-link"
                          data-cnstrc-item-section="${ sectionSearchSuggestions }"
                          data-cnstrc-item-name="${ result.value }"
                        >
                          <a href="${fixSiteURL(`/search?q=${encodeURIComponent(result.value)}`)}">${result.value}</a>
                        </li>
                      `)}
                    `,
                    () => html`
                      ${map(popularSearches, (result) => html`
                        <li
                          class="searchandise-content__list-link"
                          data-cnstrc-item-section="${ sectionPopularSearchSuggestions }"
                          data-cnstrc-item-name="${ result.name }"
                        >
                          <a href="${fixSiteURL(result.url)}">${result.name}</a>
                        </li>
                      `)}
                    `,
                  )}
                </ul>
              </div>
            </div>

            ${when(products.length || exploreContent, () => html`
              <div class="l-three-quarters m-full searchandise-content__right">
                <div class="searchandise-content__results searchandise-content--panel--active">
                  <section class="carousel carousel--native">
                    <header class="carousel__header">
                      <div class="searchandise-content__carousel">
                        <h1 class="searchandise-content__carousel-title">
                          ${products.length ? 'Search results' : exploreContent.title}
                        </h1>
                      </div>

                      <div class="carousel__nav carousel__nav--active">
                        <button is="mv-carousel-button" container=".carousel" delta="-1" class="slick-arrow slick-prev">
                          <img src="/images/2020/arrow-left-black.svg" alt="Previous page">
                        </button>
                        <button is="mv-carousel-button" container=".carousel" delta="1" class="slick-arrow slick-next">
                          <img src="/images/2020/arrow-right-black.svg" alt="Next page">
                        </button>
                      </div>
                    </header>

                    ${when(
                      products.length,
                      () => html`
                        <mv-carousel class="carousel__content">
                          ${map(products ?? [], (result, i) => resultTemplate({
                            lazy: i > 4,
                            result,
                            locale,
                            currency,
                            eventId,
                            selectedVariation: selectedVariations?.[result.data.id] ?? result.data.variation_id,
                            setSelectedVariation: ({ item, variation }) => store.dispatch(selectVariation({ item, variation })),
                          }))}
                        </mv-carousel>

                        <footer class="carousel__footer">
                          <a href="${fixSiteURL(products.length > 1 ? `/search?q=${encodeURIComponent(query)}` : products[0].data.url)}"
                            class="button button--alt js-gtm-element"
                            data-gatype="Searchandise"
                            data-galabel="Search Results - Carousel"
                          >
                              <span>
                                ${when(
                                  products.length === 1,
                                  () => html`
                                    View result
                                  `,
                                  () => html`
                                    View all&nbsp;<span class="searchandise-content__results-count">${totalResults}</span>&nbsp;results
                                  `,
                                )}

                                <img src="/images/2020/arrow-right-white.svg" width="14px" height="16px" alt="">
                              </span>
                            </a>
                        </footer>
                      `,
                      () => html`
                        <mv-carousel class="carousel__content">
                          ${map(exploreContent.tiles, (tile) => html`
                            <div
                              class="searchandise-content__tile"
                              data-cnstrc-item-section="Categories"
                              data-cnstrc-item-name="${tile.title}"
                            >
                              <a href="${fixSiteURL(tile.url)}" class="searchandise-content__tile-link js-gtm-element">
                                <figure class="searchandise-content__tile-image">
                                  ${unsafeHTML(tile.image)}
                                </figure>
                                <div class="searchandise-content__tile-content">
                                  <p class="searchandise-content__tile-title">${tile.title}</p>
                                </div>
                              </a>
                            </div>
                          `)}
                        </mv-carousel>

                        <footer class="carousel__footer">
                          <a
                            href="${fixSiteURL(exploreContent.button.url)}"
                            class="button button--alt js-gtm-element"
                            data-gatype="Searchandise"
                            data-galabel="Explore - Carousel"
                            data-cnstrc-btn="${exploreContent.button.label}"
                          >
                            <span>
                              ${exploreContent.button.label}
                              <img src="/images/2020/arrow-right-white.svg" width="14px" height="16px" alt="">
                            </span>
                          </a>
                      </footer>
                      `,
                    )}
                  </section>
                </div>
              </div>
            </div>
          `)}
        `,
      )}
    </div>
  `;
};

let containerCleared = false;
export const clearContainerOnce = (container) => {
  if (!containerCleared) {
    container.innerHTML = '';
    containerCleared = true;
  }
};
